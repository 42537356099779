import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: 'validation.required',
    },
    string: {
        email: 'validation.email',
        min: 'validation.min',
        max: 'validation.max',
        url: 'validation.url',
        trim: 'validation.trim',
        number: 'validation.number'
    },
    array: {
        min: 'validation.array.min',
    },
    object: {
        schema: '',
    },
});
