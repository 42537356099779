// assets
import { IconDashboard } from '@tabler/icons';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

// constant
const icons = { IconDashboard, CorporateFareIcon, SupervisorAccountIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'misc.Dashboard',
            type: 'item',
            url: '/super-admin',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'organizations',
            title: 'organizations.Organizations',
            type: 'collapse',
            icon: icons.CorporateFareIcon,
            children: [
                {
                    id: 'organizations-list',
                    title: 'organizations.OrganizationsList',
                    type: 'item',
                    url: '/organizations',
                    breadcrumbs: false
                },
                {
                    id: 'organizations-create',
                    title: 'organizations.CreateNewOrganization',
                    type: 'item',
                    url: '/organizations/create',
                    breadcrumbs: false
                }
            ],
            breadcrumbs: false
        },
        {
            id: 'superAdmin',
            title: 'superAdmins.SuperAdmins',
            type: 'collapse',
            icon: icons.SupervisorAccountIcon,
            children: [
                {
                    id: 'superAdmins-list',
                    title: 'superAdmins.SuperAdminsList',
                    type: 'item',
                    url: '/superAdmins',
                    breadcrumbs: false
                },
                {
                    id: 'superAdmins-create',
                    title: 'superAdmins.CreateNewSuperAdmin',
                    type: 'item',
                    url: '/superAdmins/create',
                    breadcrumbs: false
                }
            ],
            breadcrumbs: false
        }
    ]
};

export default dashboard;
