import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from '../Components/Theme/Loadable';
import { useAuthStore } from '../Stores/AuthStore';
import { observer } from 'mobx-react';
import { reactAdminUrl } from 'Config/constant';

const Dashboard = Loadable(lazy(() => import('../Pages/Authenticated/Dashboard')));
const Profile = Loadable(lazy(() => import('../Pages/Authenticated/Profile/Profile')));

const Users = Loadable(lazy(() => import('../Pages/Authenticated/Users/UsersList')));
const UsersCreate = Loadable(lazy(() => import('../Pages/Authenticated/Users/CreateUsers')));
const EditUsers = Loadable(lazy(() => import('../Pages/Authenticated/Users/EditUsers')));

const Organizations = Loadable(lazy(() => import('../Pages/Authenticated/Organization/OrganizationList')));
const OrganizationsCreate = Loadable(lazy(() => import('../Pages/Authenticated/Organization/OrganizationCreate')));
const OrganizationsEdit = Loadable(lazy(() => import('../Pages/Authenticated/Organization/OrganizationEdit')));
const OrganizationsDetailsTabs = Loadable(lazy(() => import('../Pages/Authenticated/Organization/OrganizationDetailsTabs')));

const SuperAdmins = Loadable(lazy(() => import('../Pages/Authenticated/SuperAdmin/SuperAdminList')));
const SuperAdminsCreate = Loadable(lazy(() => import('../Pages/Authenticated/SuperAdmin/SuperAdminCreate')));
// const SuperAdminEdit = Loadable(lazy(() => import('../Pages/Authenticated/SuperAdmin/SuperAdminEdit')));
// const SuperAdminDetailsTabs = Loadable(lazy(() => import('../Pages/Authenticated/SuperAdmin/SuperAdminDetailsTabs')));

const AuthenticatedRoutes = () => {
    const authStore = useAuthStore();

    return (
        <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path={reactAdminUrl} element={<Dashboard />} />
            <Route exact path="/profile" element={<Profile />} />

            <Route exact path="/organizations" element={<Organizations />} />
            <Route exact path="/organizations/create" element={<OrganizationsCreate />} />
            <Route exact path="/organizations/:id" element={<OrganizationsDetailsTabs />} />
            <Route exact path="/organizations/:id/edit" element={<OrganizationsEdit />} />

            <Route exact path="/superAdmins" element={<SuperAdmins />} />
            <Route exact path="/superAdmins/create" element={<SuperAdminsCreate />} />
            {/* <Route exact path="/superAdmins/:id" element={<SuperAdminDetailsTabs />} />
            <Route exact path="/superAdmins/:id/edit" element={<SuperAdminEdit />} /> */}

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
export default observer(AuthenticatedRoutes);
